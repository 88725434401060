import React from 'react'
import {
    SidebarContainer, 
    Icon, 
    CloseIcon, 
    SidebarWrapper, 
    SidebarMenu, 
    SidebarLink, 
    SideBtnWrap, 
    SidebarRoute
} from './SidebarElements'

const Sidebar = ({ toggle, isOpen }) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon>
                <CloseIcon onClick={toggle} />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    {/* <SidebarLink to="about" onClick={toggle} >
                        About
                    </SidebarLink> */}
                    <SidebarLink to="experience" onClick={toggle} >
                        Experience
                    </SidebarLink>
                    {/* <SidebarLink to="work" onClick={toggle} >
                        Work
                    </SidebarLink>
                    <SidebarLink to="contact" onClick={toggle} >
                        Contact
                    </SidebarLink> */}
                    <SidebarLink to="oauth" onClick={toggle} >
                        Nylas Demo
                    </SidebarLink>
                </SidebarMenu>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar
