import React from 'react'

import { ContentBlock, ContentWrapper, ContentContainer, ContentContainerLarge } from '../globalComponents.js/contentBlock'
import { HomePageHeaderLarge, HeaderMedium, HeaderSmall, HeaderLarge } from '../globalComponents.js/contentHeadings'
import { Socials } from '../globalComponents.js/socials'

import photo from "../../svgs/chase_memoji.png"

const Home = () => {
    return (
        <div>
            <ContentBlock primary>
                <ContentWrapper>
                    <ContentContainer>
                        <HeaderMedium>Hello, My Name Is</HeaderMedium>
                        <HomePageHeaderLarge>Chase Wallace</HomePageHeaderLarge>
                        <HeaderSmall>I'm a Software Engineer with a passion for Customer and Developer Experience based in Denver, Colorado</HeaderSmall>
                        <Socials/>
                    </ContentContainer>
                    <ContentContainer>
                        <img src={photo}/>
                    </ContentContainer>
                </ContentWrapper>
            </ContentBlock>
            <ContentBlock>
                <ContentWrapper>
                    <ContentContainerLarge>
                        <HeaderLarge>About Me</HeaderLarge>
                        <HeaderSmall>
                            I am a Software Engineer with a background in Web Development and Project Management. 
                            With over 5 years of professional technical experience, I use my project management skill set
                            with my experience as an engineer to design products and solutions with an emphasis
                            on Developer and Customer experience.
                        </HeaderSmall>
                        <HeaderSmall>
                            After graduating from Lambda School, I began working as a Software Engineer building
                            HR technology in a new and upcoming industry. I now work for Nylas using my project management 
                            and software engineering skill set to help design solutions for customers
                            building applications using the Nylas Platforms APIs and Components.
                        </HeaderSmall>
                    </ContentContainerLarge>
                </ContentWrapper>
            </ContentBlock>
        </div>
    )
}

export default Home