import React from 'react';
import './App.css';
import NavComponent from './components/navigation';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

//Components
import Home from './components/pages/home'
import Experience from './components/pages/experience';
import NylasAuth from './components/pages/nylasdemo';



function App() {
  return (
    <Router>

        <NavComponent/>

        <Switch>
          <Route path="/" exact component={Home}/>
          <Route path="/experience" exact component={Experience}/>
          <Route path="/oauth" component={NylasAuth}/>
          <Route path="/oauth/callback"/>
        </Switch>

    </Router>
  );
}

export default App;
