import React, { useState } from 'react'
import NavBar from './Navbar'
import Sidebar from './Sidebar'

const NavComponent = () => {

    const [isOpen, setIsOpen] = useState(false)

    const toggleIsOpen = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <NavBar toggle={toggleIsOpen}/>
            <Sidebar isOpen={isOpen} toggle={toggleIsOpen}/>
        </>
    )
}

export default NavComponent
