import React from 'react'

import { ContentBlock, ContentWrapper, ContentContainer } from '../globalComponents.js/contentBlock'
import { HeaderLarge, HeaderMedium, SubHeaderMedium, LISmall } from '../globalComponents.js/contentHeadings'

import nylaslogo from "../../svgs/nylas_logo.png"
import wurklogo from "../../svgs/wurk_logo.png"

const Experience = () => {
    return (
        <div>
            <ContentBlock primary>
                <ContentWrapper>
                    <ContentContainer>
                        <HeaderLarge>Software Implementation Engineer</HeaderLarge>
                        <HeaderMedium>Nylas</HeaderMedium>
                        <SubHeaderMedium>June 2021 - Present</SubHeaderMedium>
                        <ul>
                            <LISmall>Work closely with external Developers to help design solutions, 
                                fix bugs, and integrate the Nylas product into their platform.
                            </LISmall>
                            <LISmall>Created testing guidelines for new features, 
                                as well as added new features to our codebase for existing and potential customers, 
                                and lead cross team trainings to educate other developers on new and in depth features.
                            </LISmall>
                            <LISmall>Helped create customer onboarding protocols and improved developer experience to 
                                push the average NPS score month over month from 92 to 98.
                            </LISmall>
                        </ul>
                    </ContentContainer>
                    <ContentContainer>
                        <img src={nylaslogo}/>
                    </ContentContainer>
                </ContentWrapper>
            </ContentBlock>

            <ContentBlock>
                <ContentWrapper>
                    <ContentContainer>
                        <img src={wurklogo}/>
                    </ContentContainer>
                    <ContentContainer>
                        <HeaderLarge>Software Engineer II</HeaderLarge>
                        <HeaderMedium>Wurk, Inc.</HeaderMedium>
                        <SubHeaderMedium>September 2020 - June 2021</SubHeaderMedium>
                        <ul>
                            <LISmall>Created an  ETL to maintain and move data in realtime 
                                from MongoDB into BigQuery for our analytics platform.
                            </LISmall>
                            <LISmall>Designed the architecture and led implementation of a real 
                                time email notification system, using Sendgrid, that is able to 
                                identify customer versus internal errors and issue the notifications 
                                appropriately.
                            </LISmall>
                            <LISmall>Built and maintained a retirement plan integration that calculates 
                                and sends 401k and Roth contributions based on employee contribution rates, 
                                including employer matches, weekly for 50,000+ employees to retirement plan 
                                providers.
                            </LISmall>
                        </ul>
                    </ContentContainer>
                </ContentWrapper>
            </ContentBlock>
        </div>
    )
}

export default Experience