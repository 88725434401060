import React from 'react'
import { Nav, NavWrapper, NavLink, Bars, NavMenu, SiteLogo, BarWrapper, Anchor} from './NavBarElements.js'

const NavBar = ({ toggle }) => {
    return (
        <NavWrapper>
            <Nav>
                <NavLink to="/" exact>
                    <SiteLogo/>
                </NavLink>
                <BarWrapper>
                    <Bars onClick={toggle}/>
                </BarWrapper>
                <NavMenu>
                    <NavLink to="/" exact activeStyle>
                        Home
                    </NavLink>
                    {/* <NavLink to="/about" activeStyle>
                        About
                    </NavLink> */}
                    <NavLink to="/experience" activeStyle>
                        Experience
                    </NavLink>
                    {/* <NavLink to="/work" activeStyle>
                        Work
                    </NavLink>
                    <NavLink to="/contact" activeStyle>
                        Contact
                    </NavLink> */}
                    <Anchor href="https://nylas.chasewallace.com">
                        Nylas
                    </Anchor>
                </NavMenu>
            </Nav>
        </NavWrapper>
    )
}

export default NavBar
