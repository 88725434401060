import styled from 'styled-components'

export const ContentBlock = styled.div`
    background: ${props => props.primary ? "white" : "#f7f7fc"};
    position: relative;
    z-index: 1;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 40px;
    padding-left: 40px;
    box-sizing: border-box;
`

export const ContentWrapper = styled.div`
    display: flex;
    max-width: 1280px;
    margin: 0 auto;
    flex-wrap: wrap;
    align-content: stretch;
    box-sizing: border-box;
    padding: 20px 0px;
`

export const ContentContainer = styled.div`
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
`

export const ContentContainerLarge = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
`