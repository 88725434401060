import styled from 'styled-components'

export const Input = styled.input`
    padding: 10px 20px;
    border: 1px solid black;
    border-radius: 10px;
    width: 400px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
`

export const Button = styled.a`
    padding: 10px 20px;
    margin: 0 auto;
    width: 250px;
    border: 4px solid #547FFF;
    font-weight: 700;
    letter-spacing: -.01em;
    font-family: 'Poppins', sans-serif;
    background: white;
    border-radius: 15px;
    color: #547FFF;
    font-size: 1.5rem;
    cursor: pointer;
`