import React, { useState } from 'react';

import { ContentBlock, ContentContainerLarge, ContentWrapper } from '../globalComponents.js/contentBlock';
import { Input, Button } from '../globalComponents.js/formComponents';

const NylasAuth = () => {

    const [email, setEmail] = useState('')
    const [link, setLink] = useState('https://auth.chasewallace.com/oauth/authorize?client_id=6p6cmonblrlx3fpy5orzjroue&redirect_uri=https://chasewallace.com/oauth/callback&response_type=code&scopes=calendar,email,contacts')
    const queryParams = new URLSearchParams(window.location.search)
    const code = queryParams.get("code")

    function handleChange(event) {
        setEmail(event.target.value);
        setLink(`https://auth.chasewallace.com/oauth/authorize?client_id=6p6cmonblrlx3fpy5orzjroue&redirect_uri=https://chasewallace.com/oauth/callback&response_type=code&scopes=calendar,email,contacts&login_hint=${event.target.value}`)
    }

    return (
        <div>
            {code != null && 
                <ContentBlock primary>
                    <ContentWrapper>
                        <ContentContainerLarge>
                            <h2>Callback Code: {code}</h2>
                        </ContentContainerLarge>
                    </ContentWrapper>
                </ContentBlock>
            }
            <ContentBlock primary>
                <ContentWrapper>
                    <ContentContainerLarge>
                        <Input onChange={handleChange} placeholder="Email"/>
                        <Button href={link}>Submit</Button>
                    </ContentContainerLarge>
                </ContentWrapper>
            </ContentBlock>
        </div>
    )

}

export default NylasAuth