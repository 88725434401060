import styled from 'styled-components'
import {FaGithub, FaLinkedin, FaTwitter, FaInstagram} from 'react-icons/fa'

const Github = styled(FaGithub)`
`
const LinkedIn = styled(FaLinkedin)`
`

const Twitter = styled(FaTwitter)`
`

const Instagram = styled(FaInstagram)`
`

const SocialLink = styled.a`
    display: flex;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color: #1d1f21;
    cursor: pointer;
    padding: 10px 25px 0px 0px;

    &:hover {
        color:#547FFF;
        transition: 0.2s ease-in-out;
    }
`

const SocialWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0 auto;
`

export const Socials = () => {
    return (
        <SocialWrapper>
            <SocialLink href="https://github.com/wallacecs007" target="_blank"><Github/></SocialLink>
            <SocialLink href="https://www.linkedin.com/in/charles-wallace-se/" target="_blank"><LinkedIn/></SocialLink>
            <SocialLink href="https://twitter.com" target="_blank"><Twitter/></SocialLink>
        </SocialWrapper>
    )
}