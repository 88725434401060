import styled from 'styled-components'

export const HeaderLarge = styled.div`
    margin-bottom: 20px;
    font-size: 2.3rem;
    line-height: 1.15;
    font-weight: 700;
    letter-spacing: -.01em;
    font-family: 'Poppins', sans-serif;
    color: #547FFF;
    `

export const HomePageHeaderLarge = styled.div`
    margin-bottom: 20px;
    font-size: 4rem;
    line-height: 1.15;
    font-weight: 700;
    letter-spacing: -.01em;
    font-family: 'Poppins', sans-serif;
    color: #547FFF;
    `

export const HeaderMedium = styled.div`
    margin-bottom: 20px;
    font-size: 2rem;
    line-height: 1.15;
    font-weight: 700;
    letter-spacing: -.01em;
    font-family: 'Poppins', sans-serif;
    color: #1d1f21;
    `

export const SubHeaderMedium = styled.div`
    margin-bottom: 20px;
    font-size: 1.8rem;
    line-height: 1.15;
    font-weight: 700;
    letter-spacing: -.01em;
    font-family: 'Poppins', sans-serif;
    color: #8AA7FC;
    `

export const HeaderSmall = styled.div`
    margin-bottom: 20px;
    font-size: 1.3rem;
    line-height: 1.15;
    font-weight: 700;
    letter-spacing: -.01em;
    font-family: 'Poppins', sans-serif;
    color: #1d1f21;
    `

export const LISmall = styled.li`
    margin-bottom: 20px;
    margin-left: 10px;
    font-size: 1.0rem;
    line-height: 1.15;
    font-weight: 700;
    letter-spacing: -.01em;
    font-family: 'Poppins', sans-serif;
    color: #1d1f21;
    `