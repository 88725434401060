import styled from 'styled-components'
import { NavLink as Link } from 'react-router-dom'
import {FaBars} from 'react-icons/fa'

import { ReactComponent as FullStackIcon } from "../../../svgs/chase_site_icon_logo.svg"


export const SiteLogo = styled(FullStackIcon)`
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    z-index: 10;
`

export const Nav = styled.nav`
    height: 115px;
    display: flex;
    justify-content: space-between;
    padding: 0px 80px;
    z-index: 10;
    width: 500 px;

    @media screen and (max-width: 768px) {
        padding: 0px 20px;
    }

`

export const NavWrapper = styled.div`
    max-width: 1280px;
    margin: 0 auto;
`

export const NavLink = styled(Link)`
    font-family: 'Poppins', sans-serif;
    color: #171717;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1.5rem;
    height: 100%;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    font-size: 18px;
    font-weight: 600;

    &:hover {
        color:#547FFF;
        transition: all 0.2s ease-in-out;
    }

    &.active {
        color:#547FFF;
    }
`

export const Anchor = styled.a`
    font-family: 'Poppins', sans-serif;
    color: #171717;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1.5rem;
    height: 100%;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    font-size: 18px;
    font-weight: 600;

    &:hover {
        color:#547FFF;
        transition: all 0.2s ease-in-out;
    }

    &.active {
        color:#547FFF;
    }
`

export const Bars = styled(FaBars)`
    display: none;
    color: #171717;

    @media screen and (max-width: 768px) {
        height: auto;
        width: 30px;
        display: flex;
        align-items: center;
        text-decoration: none;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        z-index: 10;

        &.active {
            color:#547FFF;
        }

    }
`

export const BarWrapper = styled.div`
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1.5rem;
    height: 100%;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    font-size: 14px;
`

export const NavMenu = styled.div`
    display: flex;
    align-items: center;
    margin-right: -24px;

    @media screen and (max-width: 768px) {
        display: none;
    }
`

// export const NavBtn = styled.nav`
//     display: flex;
//     align-items: center;
//     margin-right: 24px;

//     @media screen and (max-width: 768px) {
//         display: none;
//     }
// `

// export const NavBtnLink = styled(Link)`
//     border: 1px solid #fafafa;
//     padding: 10px 22px;
//     color: #fafafa;
//     outline: none;
//     cursor: pointer;
//     transition: all 0.2s ease-in-out;
//     text-decoration: none;

//     &:hover {
//         transition: all 0.2s ease-in-out;
//         color: #01bf71;
//         border: 1px solid #01bf71;
//     }

//     &.active {
//         color: #01bf71;
//         border: 1px solid #01bf71;
//     }

//     &:visited {
//         text-decoration: none;
//         color: default;
//     }
// `